import React, { useEffect, useState } from "react";
import { getCurrentUser } from "../../../services/auth";
import Statements from "./statements";

export default function StatementGrid({
  id,
  from,
  disablePoints,
  amountField = "amount_user"
}) {
  const [openStatements, setOpenStatements] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);

  const handleClick = (type) => {
    setOpenStatements(type);
  };

  useEffect(() => {
    const user = getCurrentUser();
    setHasPermission(user?.permissions?.includes('statement.write'));
  }, []);

  return (
    <>
      { hasPermission && (
        <div>
          Extrato:
          <ul>
            <li onClick={() => handleClick('amount')} style={{ color: 'blue', cursor: 'pointer', marginBottom: 10 }}>Comissões</li>
            {!disablePoints && <li onClick={() => handleClick('points')} style={{ color: 'blue', cursor: 'pointer' }}>Pontos</li>}
          </ul>

          {openStatements && (
            <Statements
              open={openStatements}
              onClose={() => setOpenStatements(false)}
              apiStatements={`/statement/${from}/${id}/${openStatements}`}
              setFirstDayOfMonth={false}
              amountField={amountField}
            />
          )}
        </div>
      )}
    </>
  );
}