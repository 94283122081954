import React from "react"
import { Controller } from "react-hook-form";
import { Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import FieldErrors from "../form/field-errors";
import FormService from "../../services/form";

export default function OrganizationForm({
  control,
  errorsForm,
  errorsApi,
  data
}) {
  return (
    <>
      <Controller
        name="name"
        control={control}
        rules={{ required: true }}
        defaultValue={data?.name}
        render={({ field }) => (
          <>
            <TextField {...field}
              label="Nome*"
              variant="outlined"
              margin="normal"
              error={FormService.hasError('name', errorsForm, errorsApi)}
              fullWidth
            />
            <FieldErrors name="name" errorsForm={errorsForm} errorsApi={errorsApi} />
          </>
        )}
      />

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Controller
            name="commission_organization"
            control={control}
            defaultValue={data?.commission_organization ?? 0}
            render={
              ({ field }) =>
                <>
                  <InputLabel style={{ marginTop: 10, marginBottom: 10 }}>Comissão da organização</InputLabel>
                  <Select {...field}
                    label="Comissão da organização"
                    variant="outlined"
                    error={FormService.hasError('commission_organization', errorsForm, errorsApi)}
                    fullWidth
                  >
                    <MenuItem value={0}>0%</MenuItem>
                    <MenuItem value={0.01}>1%</MenuItem>
                    <MenuItem value={0.02}>2%</MenuItem>
                    <MenuItem value={0.03}>3%</MenuItem>
                    <MenuItem value={0.04}>4%</MenuItem>
                    <MenuItem value={0.05}>5%</MenuItem>
                    <MenuItem value={0.06}>6%</MenuItem>
                    <MenuItem value={0.07}>7%</MenuItem>
                    <MenuItem value={0.08}>8%</MenuItem>
                    <MenuItem value={0.09}>9%</MenuItem>
                    <MenuItem value={0.10}>10%</MenuItem>
                  </Select>
                  <FieldErrors name="commission_organization" errorsForm={errorsForm} errorsApi={errorsApi} />
                </>
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="commission_users"
            control={control}
            defaultValue={data?.commission_users ?? 0}
            render={
              ({ field }) =>
                <>
                  <InputLabel style={{ marginTop: 10, marginBottom: 10 }}>Comissão dos usuários</InputLabel>
                  <Select {...field}
                    label="Comissão dos usuários"
                    variant="outlined"
                    error={FormService.hasError('commission_users', errorsForm, errorsApi)}
                    fullWidth
                  >
                    <MenuItem value={0}>0%</MenuItem>
                    <MenuItem value={0.01}>1%</MenuItem>
                    <MenuItem value={0.02}>2%</MenuItem>
                    <MenuItem value={0.03}>3%</MenuItem>
                    <MenuItem value={0.04}>4%</MenuItem>
                    <MenuItem value={0.05}>5%</MenuItem>
                    <MenuItem value={0.06}>6%</MenuItem>
                    <MenuItem value={0.07}>7%</MenuItem>
                    <MenuItem value={0.08}>8%</MenuItem>
                    <MenuItem value={0.09}>9%</MenuItem>
                    <MenuItem value={0.10}>10%</MenuItem>
                  </Select>
                  <FieldErrors name="commission_users" errorsForm={errorsForm} errorsApi={errorsApi} />
                </>
            }
          />
        </Grid>
      </Grid>
    </>
  );
}