import React, { useState } from "react"
import LayoutLogged from "../../components/layout/logged"
import Container from '@material-ui/core/Container';
import AppForm from "../../components/form/app-form";
import OrganizationForm from "../../components/pages/organization-form";
import { useForm } from "react-hook-form";
import StatementGrid from "../../components/dialog/statements/grid";
import { ifNotHaveAccessRedirect } from "../../services/auth";

export default function OrganizationUpdate() {
  const { control, handleSubmit, formState: { errors } } = useForm();
  const [errorsApi, setErrorsApi] = useState(undefined);
  const [data, setData] = useState();

  if (ifNotHaveAccessRedirect('organization.write')) return <></>;

  return (
    <LayoutLogged title={data?.name}>
      <Container >
        <AppForm
          api="/organization"
          linksPath="/organization"
          handleSubmit={handleSubmit}
          onErrors={setErrorsApi}
          onLoadData={setData}
          control
        >
          <OrganizationForm
            control={control}
            errorsForm={errors}
            errorsApi={errorsApi}
            data={data}
          />

          <br /><br />
          <StatementGrid
            id={data?.id}
            from="organization"
            amountField="amount_organization"
            disablePoints
          />
          
        </AppForm>
      </Container>
    </LayoutLogged>
  );
}